var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Reference no.")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.referral.reference))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Type")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.type))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Person Signposted")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.referral.name))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Signposted Email")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.referral.email || "-"))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Signposted Phone")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.referral.phone || "-"))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Signposted To")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c(
                    "gov-link",
                    {
                      attrs: {
                        to: {
                          name: "services-show",
                          params: { service: _vm.referral.service.id }
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.referral.service.name) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Organisation")
              ]),
              _c(
                "gov-table-cell",
                [
                  _vm.loadingOrganisation
                    ? _c("ck-loader")
                    : _c(
                        "gov-link",
                        {
                          attrs: {
                            to: {
                              name: "organisations-show",
                              params: { organisation: _vm.organisation.id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.organisation.name) +
                              "\n        "
                          )
                        ]
                      )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Signposted To Email")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.referral.service.referral_email))
              ])
            ],
            1
          ),
          !_vm.isSelfReferral
            ? [
                _c(
                  "gov-table-row",
                  [
                    _c(
                      "gov-table-header",
                      { attrs: { top: "", scope: "row" } },
                      [_vm._v("Champion Name")]
                    ),
                    _c("gov-table-cell", [
                      _vm._v(_vm._s(_vm.referral.referee_name))
                    ])
                  ],
                  1
                ),
                _c(
                  "gov-table-row",
                  [
                    _c(
                      "gov-table-header",
                      { attrs: { top: "", scope: "row" } },
                      [_vm._v("Champion Email")]
                    ),
                    _c("gov-table-cell", [
                      _vm._v(_vm._s(_vm.referral.referee_email || "-"))
                    ])
                  ],
                  1
                ),
                _c(
                  "gov-table-row",
                  [
                    _c(
                      "gov-table-header",
                      { attrs: { top: "", scope: "row" } },
                      [_vm._v("Champion Phone")]
                    ),
                    _c("gov-table-cell", [
                      _vm._v(_vm._s(_vm.referral.referee_phone || "-"))
                    ])
                  ],
                  1
                ),
                _c(
                  "gov-table-row",
                  [
                    _c(
                      "gov-table-header",
                      { attrs: { top: "", scope: "row" } },
                      [_vm._v("Champion Organisation")]
                    ),
                    _c("gov-table-cell", [
                      _vm._v(_vm._s(_vm.referral.referee_organisation))
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Consent")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.consented))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Status")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c("gov-tag", [
                    _vm._v(_vm._s(_vm._f("status")(_vm.referral.status)))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Days Remaining")
              ]),
              _c("gov-table-cell", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.statusLastUpdated(_vm.referral)) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Date/Time")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.formatDateTime(_vm.referral.created_at)))
              ])
            ],
            1
          ),
          _vm.referral.status === "completed"
            ? _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Scheduled for deletion")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(
                      _vm._s(
                        _vm.formatDate(
                          _vm.autoDeleteDate(_vm.referral.updated_at)
                        )
                      )
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }